export const artworkSubtypes = [
  'artist',
  'artwork_type',
  'category',
  'decade',
  'movement',
  'special_collection',
  'site'
].join('|');

export const artInvestigationSubtypes = [
  'look_at_art_grade',
  'look_at_art_technique',
  'look_at_art_theme'
].join('|');

export const artMakingSubtypes = ['making_art_technique', 'making_art_theme'].join('|');

export const closingSoonSeconds = 1814000; // 21 days in seconds
