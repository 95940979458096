import IconClose from '@/assets/svgs/IconClose.js';
import { useA11y, useImageProxy } from '@/composables/Common.js';
import { useMainStore } from '@/stores/mainStore';

export const lightbox = {
  beforeMount(el, binding) {
    const { proxySource } = useImageProxy();

    const box = document.createElement('div');
    const close = htmlToElement(IconClose.svg, 'svg');
    const container = document.createElement('div');
    const desc =
      binding.value.description && binding.value.description.includes('<p>')
        ? htmlToElement(binding.value.description, 'p')
        : htmlToElement(`<p>${binding.value.description}</p>`, 'p');
    const image = document.createElement('img');
    const span = document.createElement('span');
    const trigger = document.createElement('button');
    const wrap = document.createElement('picture');

    // construct close button
    trigger.innerText = 'Close';
    trigger.appendChild(close);
    trigger.classList.add('button-tertiary', 'close');

    span.appendChild(trigger);

    if (binding.value.description) {
      // captions
      container.appendChild(desc);
    }

    // construct image
    image.alt = binding.value.alt || binding.value.altText;
    image.src = binding.value.sourceUrl
      ? proxySource(binding.value.sourceUrl)
      : proxySource(binding.value.src);
    image.srcset = binding.value.srcset || '';
    image.sizes = '70vw';

    wrap.appendChild(image);
    container.prepend(wrap);

    // construct lightbox container
    box.classList.add('lightbox-container');
    container.classList.add('lightbox-wrap');
    container.setAttribute('role', 'dialog');

    if (
      binding.value.height &&
      binding.value.width &&
      binding.value.height > binding.value.width - 50
    ) {
      box.classList.add('portrait');
    }

    el.onkeydown = (evt) => {
      if (evt.code === 'Enter' || evt.code === 'Space') {
        evt.preventDefault();
        activateLightbox(el, box, container, span);
      }
    };
    el.onclick = () => {
      activateLightbox(el, box, container, span);
    };
  },
};

function activateLightbox(
  el: HTMLButtonElement,
  box: HTMLDivElement,
  container: HTMLDivElement,
  span: HTMLSpanElement,
): void {
  const store = useMainStore();
  const { setValue } = store;
  const { trapFocus } = useA11y();

  ['click', 'touch', 'keyup'].map((type) => {
    box.addEventListener(type, (event) => checkCloseEvent(event, box, el, setValue));
  });

  el.parentElement.classList.add('active');

  container.prepend(span);
  box.appendChild(container);

  const app = document.getElementById('app');

  // engage overlay and attach lightbox to app
  setValue({ type: 'overlay', value: true });
  app?.appendChild(box);

  document.documentElement.classList.add('body-modal');
  box.classList.remove('hidden');
  box.classList.add('lightbox-active');

  toggleInert(true);
  trapFocus(box);

  // Push to Google Analytics
  if (el.hasAttribute('ga4-event')) {
    const gaEvent = el.getAttribute('ga4-event').replace(/'/g, '"');

    if (gaEvent) {
      let eventData;

      try {
        eventData = JSON.parse(gaEvent);
        window.dataLayer.push({
          event: 'ga4-event',
          name: eventData.name,
          params: eventData.params,
        });
      } catch {
        // eslint-disable-next-line
        console.log('Cannot parse: ' + gaEvent);
      }
    }
  }
}

function checkCloseEvent(
  event: Event | MouseEvent | KeyboardEvent,
  box: HTMLDivElement,
  el: HTMLButtonElement,
  setValue,
): void {
  if (
    (event.type !== 'keyup' &&
      event.target &&
      (event.target.attributes === undefined || event.target.tagName !== 'IMG')) ||
    event.code === 'Escape'
  ) {
    ['click', 'touch', 'keyup'].map((type) => {
      box.removeEventListener(type, (event) => checkCloseEvent(event, box, el, setValue));
    });

    setValue({ type: 'overlay', value: false });
    toggleInert(false);

    document.documentElement.classList.remove('body-modal');
    box.classList.remove('lightbox-active');
    box.classList.add('hidden');

    if (box?.parentElement) {
      box.parentElement.classList.remove('active');
    }

    el.focus();
  }
}

function htmlToElement(string: string, type: string): Node {
  const element = document.createElement(type);

  element.innerHTML = string.trim();

  return element.firstChild;
}

function toggleInert(value: boolean): void {
  const skipLink = document.getElementById('skip-link');
  const mainNav = document.getElementById('main-nav');

  if (value && skipLink && mainNav) {
    skipLink.setAttribute('aria-hidden', 'true');
    skipLink.setAttribute('inert', '');
    mainNav.setAttribute('aria-hidden', 'true');
    mainNav.setAttribute('inert', '');
  }

  if (!value && skipLink && mainNav) {
    skipLink.removeAttribute('aria-hidden');
    skipLink.removeAttribute('inert');
    mainNav.removeAttribute('aria-hidden');
    mainNav.removeAttribute('inert');
  }
}
