import { artInvestigationSubtypes, artMakingSubtypes, artworkSubtypes } from '@/config/app';
import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAudioStore } from '@/stores/audioStore';
import { useMainStore } from '@/stores/mainStore.js';

// lazy loading
const ArticlePost = () => import('./components/article/ArticlePost.vue');
const ArticleSection = () => import('./components/article/ArticleSection.vue');
const ArtworkPost = () => import('./components/artwork/ArtworkPost.vue');
const ArtworkTerm = () => import('./components/artwork/ArtworkTerm.vue');
const ArtworkTermArtist = () => import('./components/artwork/ArtworkTermArtist.vue');
const ArtworkSection = () => import('./components/artwork/ArtworkSection.vue');
const AudioSection = () => import('./components/audio/AudioSection.vue');
const AudioTracks = () => import('./components/audio/AudioTracks.vue');
const AudioPlaylists = () => import('./components/audio/AudioPlaylists.vue');
const AudioPlaylist = () => import('./components/audio/AudioPlaylist.vue');
const AudioTrack = () => import('./components/audio/AudioTrack.vue');
const CalendarEvent = () => import('./components/calendar/CalendarEvent.vue');
const CalendarSeries = () => import('./components/calendar/CalendarSeries.vue');
const CalendarSection = () => import('./components/calendar/CalendarSection.vue');
const CollectionOnline = () => import('./components/CollectionOnline.vue');
const ExhibitionsArchive = () => import('./components/exhibitions/ExhibitionsArchive.vue');
const ExhibitionsPage = () => import('./components/exhibitions/ExhibitionsPage.vue');
const ExhibitionsPost = () => import('./components/exhibitions/ExhibitionsPost.vue');
const FindingAidsCollection = () => import('./components/findingAids/FindingAidsCollection.vue');
const FindingAidsFile = () => import('./components/findingAids/FindingAidsFile.vue');
const FindingAidsRequest = () => import('./components/findingAids/FindingAidsRequest.vue');
const FindingAidsSeries = () => import('./components/findingAids/FindingAidsSeries.vue');
const Home = () => import('@/components/Home.vue');
const LearningThroughArtPostInvestigation = () =>
  import('./components/learningThroughArt/LearningThroughArtPostInvestigation.vue');
const LearningThroughArtPostMaking = () =>
  import('./components/learningThroughArt/LearningThroughArtPostMaking.vue');
const LearningThroughArtInvestigation = () =>
  import('./components/learningThroughArt/LearningThroughArtInvestigation.vue');
const LearningThroughArtCurriculum = () =>
  import('./components/learningThroughArt/LearningThroughArtCurriculum.vue');
const LearningThroughArtMaking = () =>
  import('./components/learningThroughArt/LearningThroughArtMaking.vue');
const LearningThroughArtTaxonomy = () =>
  import('./components/learningThroughArt/LearningThroughArtTaxonomy.vue');
const NewsPost = () => import('./components/news/NewsPost.vue');
const NewsSection = () => import('./components/news/NewsSection.vue');
const NotFound = () => import('./components/NotFound.vue');
const PageController = () => import('./components/page/PageController.vue');
const PlanYourVisit = () => import('./components/planYourVisit/PlanYourVisit.vue');
const PressReleasePost = () => import('./components/press/PressReleasePost.vue');
const PressReleaseSection = () => import('./components/press/PressReleaseSection.vue');
const PublicationPost = () => import('./components/publication/PublicationPost.vue');
const PublicationSection = () => import('./components/publication/PublicationSection.vue');
const Search = () => import('./components/search/Search.vue');
const TeachingMaterialsPackage = () =>
  import('./components/teachingMaterials/TeachingMaterialsPackage.vue');
const TeachingMaterialsTopic = () =>
  import('./components/teachingMaterials/TeachingMaterialsTopic.vue');
const TeachingMaterialsSection = () =>
  import('./components/teachingMaterials/TeachingMaterialsSection.vue');
const VideoPost = () => import('./components/video/VideoPost.vue');
const VideoSection = () => import('./components/video/VideoSection.vue');
const VideoTaxonomy = () => import('./components/video/VideoTaxonomy.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/blogs/:category/:slug',
    redirect: { name: 'ArticlePost' },
  },
  {
    component: ArticlePost,
    name: 'ArticlePost',
    path: '/articles/:category/:slug',
    props: true,
  },
  {
    path: '/blogs',
    redirect: { name: 'ArticleSection' },
  },
  {
    component: ArticleSection,
    name: 'ArticleSection',
    path: '/articles',
    props: true,
  },
  {
    path: '/blogs/page/:page',
    redirect: { name: 'ArticleSectionPaged' },
  },
  {
    component: ArticleSection,
    name: 'ArticleSectionPaged',
    path: '/articles/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    path: '/blogs/:type(author|category|tag)/:slug([^/]+)/:subcategory([^/]+)?',
    redirect: { name: 'ArticleTerm' },
  },
  {
    component: ArticleSection,
    name: 'ArticleTerm',
    path: '/articles/:type(author|category|tag)/:slug([^/]+)/:subcategory([^/]+)?',
    props: true,
  },
  {
    path: '/blogs/:type(author|category|tag)/:slug/:subcategory([^/]+)?/page/:page',
    redirect: { name: 'ArticleTermPaged' },
  },
  {
    component: ArticleSection,
    name: 'ArticleTermPaged',
    path: '/articles/:type(author|category|tag)/:slug/:subcategory([^/]+)?/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: ArtworkTermArtist,
    name: 'ArtworkTermArtist',
    path: '/artwork/artist',
  },
  {
    component: ArtworkTerm,
    name: 'ArtworkTerm',
    path: `/artwork/:subtype(${artworkSubtypes})/:search([^/]+)?`,
    props: true,
  },
  {
    component: ArtworkTerm,
    name: 'ArtworkTermPaged',
    path: `/artwork/:subtype(${artworkSubtypes})/:search([^/]+)?/page/:page`,
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: ArtworkPost,
    name: 'ArtworkPost',
    path: '/artwork/:slugname',
    props: true,
  },
  {
    component: ArtworkSection,
    name: 'ArtworkSection',
    path: '/artwork',
    props: true,
  },
  {
    component: ArtworkSection,
    name: 'ArtworkSectionPaged',
    path: '/artwork/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioPlaylist,
    name: 'AudioPlaylist',
    path: '/audio/playlist/:slug',
    props: true,
  },
  {
    component: AudioTrack,
    name: 'AudioTrack',
    path: '/audio/track/:slug',
    props: true,
  },
  {
    component: AudioTracks,
    name: 'AudioTracks',
    path: '/audio/track',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioTracks,
    name: 'AudioTracksPaged',
    path: '/audio/track/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioPlaylists,
    name: 'AudioPlaylists',
    path: '/audio/playlist',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioPlaylists,
    name: 'AudioPlaylistsPaged',
    path: '/audio/playlist/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioSection,
    name: 'AudioTag',
    path: '/audio/tag/:tag',
    props: true,
  },
  {
    component: AudioSection,
    name: 'AudioTagPaged',
    path: '/audio/tag/:tag/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioSection,
    name: 'AudioSection',
    path: '/audio',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: AudioSection,
    name: 'AudioSectionPaged',
    path: '/audio/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: CalendarSeries,
    name: 'CalendarSeries',
    path: '/event/:type(series|event_series)/:slug',
    props: true,
  },
  {
    component: CalendarEvent,
    name: 'CalendarEvent',
    path: '/event/:slug/:date?',
    props(route) {
      const props = { ...route.params };
      if (!props.date) {
        props.date = '';
      }
      return props;
    },
  },
  {
    component: CalendarSection,
    name: 'CalendarSection',
    path: '/calendar/:view(day|event_date|event_month|month)?/:date?',
    props(route) {
      const props = { ...route.params };
      if (!props.date) {
        props.date = appConfig.today.isoDate;
      }
      if (!props.view) {
        props.view = 'day';
      }
      props.view = props.view === 'event_date' ? 'day' : props.view;
      props.view = props.view === 'event_month' ? 'month' : props.view;
      return props;
    },
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsArchive',
    path: '/exhibition',
    props: true,
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsArchivePage',
    path: '/exhibition/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsArchiveTerm',
    path: '/exhibition/:type(site|year)/:term',
    props: true,
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsArchiveTermPage',
    path: '/exhibition/:type(site|year)/:term/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsPast',
    path: '/exhibitions/past',
    props: { past: true },
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsPastPage',
    path: '/exhibitions/past/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      props.past = true;
      return props;
    },
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsPastTerm',
    path: '/exhibitions/past/:type(site|year)/:term',
    props(route) {
      const props = { ...route.params };
      props.past = true;
      return props;
    },
  },
  {
    component: ExhibitionsArchive,
    name: 'ExhibitionsPastTermPage',
    path: '/exhibitions/past/:type(site|year)/:term/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      props.past = true;
      return props;
    },
  },
  {
    component: ExhibitionsPage,
    name: 'ExhibitionsPage',
    path: '/exhibitions',
    props: true,
  },
  {
    component: ExhibitionsPost,
    name: 'ExhibitionsPost',
    path: '/exhibition/:slug',
    props: true,
  },
  {
    component: FindingAidsCollection,
    name: 'FindingAidsCollection',
    path: '/finding-aids/collection/:slug',
    props: true,
  },
  {
    component: FindingAidsFile,
    name: 'FindingAidsFile',
    path: '/finding-aids/file/:slug',
    props: true,
  },
  {
    component: FindingAidsSeries,
    name: 'FindingAidsSeries',
    path: '/finding-aids/series/:slug',
    props: true,
  },
  {
    component: FindingAidsRequest,
    name: 'FindingAidsRequest',
    path: '/finding-aids/requested-aids',
    props: true,
  },
  {
    component: LearningThroughArtCurriculum,
    name: 'LearningThroughArtCurriculum',
    path: '/learning-through-art/curriculum',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtCurriculum,
    name: 'LearningThroughArtCurriculumPaged',
    path: '/learning-through-art/curriculum/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtInvestigation,
    name: 'LearningThroughArtInvestigation',
    path: '/learning-through-art/art-investigation',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtInvestigation,
    name: 'LearningThroughArtInvestigationPaged',
    path: '/learning-through-art/art-investigation/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtMaking,
    name: 'LearningThroughArtMaking',
    path: '/learning-through-art/art-making',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtMaking,
    name: 'LearningThroughArtMakingPaged',
    path: '/learning-through-art/art-making/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtTaxonomy,
    name: 'LearningThroughArtTaxonomy',
    path: `/learning-through-art/:type(art-investigation|art-making)/:subtype(${artMakingSubtypes}|${artInvestigationSubtypes})/:slug`,
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },

  {
    component: LearningThroughArtTaxonomy,
    name: 'LearningThroughArtTaxonomyPaged',
    path: `/learning-through-art/:type(art-investigation|art-making)/:subtype(${artMakingSubtypes}|${artInvestigationSubtypes})/:slug/page/:page`,
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: LearningThroughArtPostInvestigation,
    name: 'LearningThroughArtPostInvestigation',
    path: '/learning-through-art/art-investigation/:slug',
    props: true,
  },
  {
    component: LearningThroughArtPostMaking,
    name: 'LearningThroughArtPostMaking',
    path: '/learning-through-art/art-making/:slug',
    props: true,
  },
  {
    component: NewsSection,
    name: 'NewsSection',
    path: '/news',
    props: true,
  },
  {
    component: NewsSection,
    name: 'NewsSectionPaged',
    path: '/news/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: NewsPost,
    name: 'NewsPost',
    path: '/news/:slug',
    props: true,
  },
  {
    component: PressReleaseSection,
    name: 'PressReleaseSection',
    path: '/press-release',
    props: true,
  },
  {
    component: PressReleaseSection,
    name: 'PressReleaseSectionPaged',
    path: '/press-release/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: PressReleasePost,
    name: 'PressReleasePost',
    path: '/press-release/:slug',
    props: true,
  },
  {
    component: PublicationSection,
    name: 'PublicationSection',
    path: '/publication',
    props: true,
  },
  {
    component: PublicationSection,
    name: 'PublicationSectionPaged',
    path: '/publication/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: PublicationSection,
    name: 'PublicationTaxSection',
    path: '/publication/:subtype(publication_subject|publication_author)/:slug',
    props: true,
  },
  {
    component: PublicationSection,
    name: 'PublicationTaxSectionPaged',
    path: '/publication/:subtype(publication_subject|publication_author)/:slug/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: PublicationPost,
    name: 'PublicationPost',
    path: '/publication/:slug',
    props: true,
  },
  {
    component: TeachingMaterialsTopic,
    name: 'TeachingMaterialsTopic',
    path: '/teaching-materials/:parent/:slug',
    props: true,
  },
  {
    component: TeachingMaterialsPackage,
    name: 'TeachingMaterialsPackage',
    path: '/teaching-materials/:slug',
    props: true,
  },
  {
    component: TeachingMaterialsSection,
    name: 'TeachingMaterialsSection',
    path: '/teaching-materials',
    props: true,
  },
  {
    path: '/video',
    name: 'Video',
    component: VideoSection,
    props: true,
  },
  {
    path: '/video/page/:page',
    name: 'VideoPage',
    component: VideoSection,
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    name: 'VideoPost',
    path: '/video/:postSlug',
    component: VideoPost,
    props: true,
  },
  {
    component: VideoTaxonomy,
    name: 'VideoTaxonomy',
    path: '/video/:type(category|tag)/:slug',
    props: true,
  },
  {
    component: VideoTaxonomy,
    name: 'VideoTaxonomyPage',
    path: '/video/:type(category|tag)/:slug/page/:page',
    props(route) {
      const props = { ...route.params };
      props.page = props.page ? +props.page : 1;
      return props;
    },
  },
  {
    component: CollectionOnline,
    name: 'CollectionOnline',
    path: '/collection-online',
    props(route) {
      const props = { ...route.params };
      props.uri = 'collection-online';
      return props;
    },
  },
  {
    component: PlanYourVisit,
    name: 'PlanYourVisit',
    path: '/plan-your-visit',
  },
  {
    component: Search,
    name: 'Search',
    path: '/search',
    props: (route) => ({
      page: route.query.page ? +route.query.page : 1,
      type: route.query.post_type,
      query: route.query.s || '',
    }),
  },
  {
    component: NotFound,
    name: 'NotFound',
    path: '/404',
  },
  {
    component: PageController,
    name: 'PagePrimary',
    path: '/:uri',
    props: true,
  },
  {
    component: PageController,
    name: 'PageSecondary',
    path: '/:uri+',
    props: true,
  },
  {
    path: '/[^//]',
    redirect: '/404',
  },
];

const router = _createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    const store = useMainStore();
    const { setValue } = store;

    // Exclude TM footnotes.
    if (to.hash && !to.hash.includes('#_')) {
      return new Promise((resolve) => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            resolve({ el: to.hash });
          });
        });
      });
    }

    if (savedPosition && savedPosition.top !== 0) {
      setValue({ type: 'savedPosition', value: savedPosition });

      return savedPosition;
    }

    if (
      (from.query.gallery && !Object.keys(to.query).length) ||
      (to.query.gallery && !Object.keys(from.query).length)
    ) {
      // preserve position for TM gallery links
      return false;
    }

    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const store = useMainStore();
  const { setBoolean, setValue } = store;

  // Rewrite abnormal URLs if possible.
  const newPath = normalizePath(to.path);

  if (newPath !== to.path && !Object.keys(to.query).length) {
    // Account for queries.
    logError(to.path);

    // Set normalized path.
    to.path = newPath;
    next(to);

    return;
  }

  if (to.path.substring(0, 2) === '//') {
    logError(to.path);
  }

  setValue({ type: 'announcement', value: 'Page loading.' });

  if (from.path !== to.path) {
    setBoolean('routing');
    setValue({ type: 'dataLoaded', value: false });
  }

  // Remove focus from focused element when routing
  document.activeElement.blur();

  // determine whether to use bootstrap
  if (from.name) {
    setValue({ type: 'useBootstrap', value: false });
  } else {
    setValue({ type: 'useBootstrap', value: true });
  }

  // handle TM direct access
  if (to.query.parent && to.query.slug) {
    const navTopic = {
      name: 'TeachingMaterialsTopic',
      params: { parent: to.query.parent, slug: decodeURI(to.query.slug) },
    };

    if (to.query.gallery) {
      navTopic.query = { gallery: to.query.gallery };
    }

    next(navTopic);

    return;
  } else if (to.query.slug) {
    const navPackage = {
      name: 'TeachingMaterialsPackage',
      params: { slug: to.query.slug },
    };

    if (to.query.gallery) {
      navPackage.query = { gallery: to.query.gallery };
    }

    next(navPackage);

    return;
  }

  next();
});

router.afterEach((to, from) => {
  const audioStore = useAudioStore();
  const store = useMainStore();

  const { open, travels } = storeToRefs(audioStore);
  const { routing } = storeToRefs(store);
  const { setBoolean } = store;
  const { updateAudioState } = audioStore;

  if (open.value) {
    travels.value = travels.value + 1;

    const params = {
      click_type: 'traveling',
      component: 'audio_player',
      content_group: 'audio',
      gtm_tag: 'interaction',
      link_text: `${travels.value}`,
    };

    window.dataLayer.push({
      event: 'ga4-event',
      name: 'click',
      params,
    });

    updateAudioState({ travels: travels.value });
  }

  if (!open.value && travels.value > 0) {
    updateAudioState({ travels: 0 });
  }

  // Gallery related exceptions
  if (!router.currentRoute.value.query.gallery) {
    if (!(Object.keys(from.query).length && from.query.gallery)) {
      if (routing.value) {
        setBoolean('routing');
      }
    }
  }
});

/**
 * Decodes encoded URI, replaces repeated slashes and performs unicode normalization.
 * Based on https://github.com/pillarjs/path-to-regexp#alternative-using-normalize
 *
 * @param {*} pathname
 */
function normalizePath(pathname) {
  return decodeURI(pathname).replace(/\/+/g, '/').normalize();
}

export function createRouter() {
  return router;
}

function logError(msg) {
  const params = {
    component: 'router.js',
    event_type: 'error',
    event_text: msg,
  };

  window.dataLayer.push({
    event: 'ga4-event',
    name: 'error',
    params,
  });
}
