export const eventListQuery = `
  query($first: Int, $today: String, $tomorrow: String) {
    eventOccurrences(first: $first, where: { dateEnd: $tomorrow, dateStart: $today }) {
      nodes {
        start {
          day
          meridiem
          month
          time
          timezone
          year
        }
        eventSlug
        title
      }
    }
  }
`;
