// https://vitejs.dev/config/#build-polyfillmodulepreload
import 'vite/modulepreload-polyfill'
import App from '@/App.vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createRouter } from '@/router';
import { lightbox } from '@/components/directives/Lightbox.ts';
import { villusClient } from '@/vue-villus';
import 'wicg-inert';

const pinia = createPinia();
const router = createRouter();

const app = createApp({
  router,
  ...App,
});

app.use(villusClient);
app.use(pinia);
app.use(router);

app.directive('lightbox', lightbox);

// globals from WP
app.provide('appConfig', appConfig);
app.provide('bootstrap', bootstrap);
app.provide('footerNav', footerNav);
app.provide('mainMenu', mainMenu);

function getMatchedComponents(route) {
  return route.matched.flatMap((record) => Object.values(record.components));
}

// Register component hook tied into router beforeResolve.
const routerReady = router.isReady().then(() => {
  router.beforeResolve((to, from, next) => {
    const components = getMatchedComponents(to);
    const promises = components.map((component) => {
      if (component.beforeRoute && component.setup) {
        return component.beforeRoute(to.params, villusClient);
      }

      if (!component.beforeRoute) {
        return Promise.resolve();
      }
    });

    Promise.all(promises)
      .then(() => next())
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        next(err);
      });
  });
});

routerReady.then(() => app.mount('#app'));
