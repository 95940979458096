import { AudioState } from '@/types/audio.store.interface';
import { defineStore } from 'pinia';

export const useAudioStore = defineStore('audioStore', {
  state: (): AudioState => ({
    accessToken: '',
    audioElement: null,
    audioId: 0,
    currentTitle: '',
    currentTrack: 0,
    expires: '',
    isInitial: true,
    isPlaying: false,
    lastFocused: null,
    open: false,
    playlistTitle: '',
    refreshToken: '',
    source: '',
    tracks: [],
    travels: 0,
  }),
  actions: {
    updateAudioState(value: AudioState) {
      Object.keys(value).map((val) => {
        if (val in this) {
          this[val] = value[val];
        }
      });
    },
  },
});
