export const exhibitionsOnViewQuery = `
  query getExhibitionsOnView($first: Int) {
    exhibitions(first: $first, where: { order: "ASC", orderKey: "end_date", site: "solomon-r-guggenheim-museum", state: "on view" }) {
      nodes {
        dates {
          end {
            day
            month
            year
          }
          label
          start {
            day
            month
            year
          }
        }
        slug
        title
      }
    }
  }
`;
