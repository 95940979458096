<template>
  <router-link v-if="isInternal" :ga4-event="ga4Data" :to="getProperties">
    <slot></slot>
  </router-link>
  <a v-else :ga4-event="ga4Data" :href="props.to" :target="getTarget" rel="noopener">
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import { AppConfig } from '@/types/global.interface';
import { computed, inject, useAttrs } from 'vue';
import { isAbsoluteUrl } from '@/utils.js';
import { RouterLink } from 'vue-router';

const attrs = useAttrs();

const props = withDefaults(
  defineProps<{
    to?: Object;
    ga4Data?: string;
  }>(),
  { to: RouterLink.props.to }
);

const appConfig = inject<AppConfig>('appConfig');

const getProperties = computed(() => {
  const properties = {
    hash: props.to.includes('#') ? `#${props.to.split('#')[1]}` : '',
    path: props.to.includes(appConfig.homeUrl) ? props.to.replace(appConfig.homeUrl, '/') : props.to,
  };

  if (props.to.includes('?q=')) {
    properties.query = { q: `${props.to.split('?q=')[1]}` };
  }

  return properties;
});

const getTarget = computed(() =>
  isAbsoluteUrl(props.to) && !props.to.includes(appConfig.homeUrl) ? attrs.behavior : undefined
);

const isInternal = computed(
  () =>
    !isAbsoluteUrl(props.to) || (isAbsoluteUrl(props.to) && props.to.includes(appConfig.homeUrl))
);
</script>
