import { createClient, defaultPlugins } from 'villus';
import { batch } from '@villus/batch';

const passcode = appConfig.graphqlAuth;

function authPlugin({ opContext }) {
  opContext.headers.Authorization = passcode ? `Basic ${passcode}` : '';
}

export const villusClient = createClient({
  url: '/graphql',
  use: [authPlugin, ...defaultPlugins(), batch({ maxOperationCount: 5 })],
});
