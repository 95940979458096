<template>
  <div v-if="data && data.text && show" id="guggen-banner" :class="$style.banner">
    <div :class="$style.frame">
      <h2 v-if="data.heading">{{ data.heading }}</h2>
      <aside>
        <component :is="icons[data.icon]" v-if="data.icon"></component>
        <div v-html="data.text"></div>
      </aside>
      <button aria-label="Close alert" class="button-reset" @click="close(data.id)">
        <IconClose></IconClose>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AppConfig } from '@/types/global.interface';
import { computed, inject, onMounted, ref, watch } from 'vue';
import IconAlert from '@/assets/svgs/IconAlert.svg?component';
import IconClockOutline from '@/assets/svgs/IconClockOutline.svg?component';
import IconClose from '@/assets/svgs/IconClose.svg?component';
import { storeToRefs } from 'pinia';
import { useA11y } from '@/composables/Common.js';
import { useMainStore } from '@/stores/mainStore.js';
import { useRoute } from 'vue-router';

interface Data {
  background_color: string;
  heading: string;
  icon: string;
  id: string;
  targets: Array<string>;
  text: string;
  text_color: string;
  text_hover_color: string;
}

const appConfig = inject<AppConfig>('appConfig');
const cookieName = 'guggenBanner';
const expiry = 'Tue, Jan 19 2038 08:14:07 GMT'; // Epochalypse.

const cookieContent = ref();
const show = ref();

const { focusFirstChild } = useA11y();
const store = useMainStore();
const route = useRoute();

const { isKeyboardUser } = storeToRefs(store);
const { setValue } = store;

const data = computed<Data>(() => appConfig.banner);
const path = computed(() => route.path);

const icons = {
  IconAlert,
  IconClockOutline,
};

onMounted(() => {
  setValue({ type: 'showBanner', value: false });
  if (document.cookie.split(';').some((item) => item.trim().startsWith(`${cookieName}=`))) {
    getCookie();
  }

  shouldShow();
});

watch(path, () => {
  shouldShow();
});

function close(id: string): void {
  setValue({ type: 'showBanner', value: false });
  cookieContent.value = id;
  show.value = false;
  setCookie();

  if (isKeyboardUser.value) {
    focusFirstChild('main-nav');
  }
}

function getCookie(): void {
  cookieContent.value = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];
}

function setCookie(): void {
  document.cookie = `
    ${cookieName}=${cookieContent.value};
    domain=${document.location.hostname};
    expires=${expiry}; path=/; SameSite=None; Secure
  `;
}

function shouldShow(): undefined {
  show.value = true;

  if (cookieContent.value && cookieContent.value === data.value.id) {
    setValue({ type: 'showBanner', value: false });
    show.value = false;

    return;
  }

  if (data.value.targets && !data.value.targets.length) {
    setValue({ type: 'showBanner', value: true });
    return;
  }

  if (
    data.value.targets &&
    data.value.targets.includes('/calendar') &&
    path.value.startsWith('/event/')
  ) {
    setValue({ type: 'showBanner', value: true });
    return;
  }

  if (data.value.targets && data.value.targets.includes('/homepage') && path.value === '/') {
    setValue({ type: 'showBanner', value: true });
    return;
  }

  if (
    data.value.targets &&
    data.value.targets.findIndex(
      (target: string) =>
        (target === '/' && path.value === target) ||
        (target !== '/' && path.value.startsWith(target)),
    ) === -1
  ) {
    setValue({ type: 'showBanner', value: false });
    show.value = false;

    return;
  }
}
</script>

<style lang="scss" module>
.banner {
  background: v-bind('data.background_color');
  color: v-bind('data.text_color');
  position: relative;
  z-index: map-get($zindex, menu) - 1;

  a {
    color: v-bind('data.text_color');

    &:hover {
      color: v-bind('data.text_hover_color');
    }
  }

  aside {
    display: flex;
  }

  button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: v-bind('data.text_hover_color');
        }
      }
    }

    &:focus {
      @include focus-outline;
      outline-color: v-bind('data.text_hover_color');
    }
  }

  .frame {
    @include container;
    padding: 2rem 1rem;
    position: relative;
  }

  h2 {
    @include heading-4;
    color: v-bind('data.text_color');
    margin-top: 0;
  }

  h2 + aside {
    margin: 1rem 0 0 0;
  }

  p {
    margin: 0;
  }

  svg {
    flex-shrink: 0;
    height: 1.25rem;
    margin: 0;
    vertical-align: sub;
    width: 1.25rem;

    path {
      fill: v-bind('data.text_color');
    }
  }

  svg + p {
    margin-left: 0.5rem;
  }
}

@media (min-width: $break-point) {
  .banner {
    button {
      right: 0.875rem;
      top: 1.5rem;
    }

    .frame {
      h2 {
        font-size: 1.75rem;
      }
    }
  }
}
</style>
