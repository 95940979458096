<template>
  <div id="main-nav" ref="frame" :class="$style.frame">
    <div v-if="menu.main.items" :class="$style.nav">
      <nav aria-label="Main Navigation" :class="$style.main">
        <PreparedAnchor
          aria-label="Guggenheim - Home"
          :class="[$style.logo, logoState ? $style.logoNavActive : $style.logoIconActive]"
          :to="'/'"
          @click="unsetActiveItem()"
        >
          <div :class="$style.logoWrapper">
            <IconLogo
              :class="[$style.logoIcon, { [$style.logoVisible]: !showLogoIconG }]"
            ></IconLogo>
          </div>
          <div :class="$style.logoWrapper">
            <NavLogo
              :class="[$style.logoIconG, { [$style.logoVisible]: showLogoIconG }]"
            ></NavLogo>
          </div>
        </PreparedAnchor>
        <ul
          id="main-menu"
          ref="main"
          :aria-hidden="isMobile() && !showMenu"
          aria-labelledby="burger-icon"
          :class="[{ [$style.active]: showMenu }, $style.top]"
        >
          <li v-if="isMobile() && activeItem">
            <button
              aria-label="Close Submenu"
              :class="$style.back"
              @click="
                activeItem = null;
                sendEvent('Close Small Submenu');
              "
            ></button>
          </li>
          <li v-if="showMenu">
            <button
              aria-controls="main-menu"
              aria-expanded="true"
              aria-label="Close Main Menu"
              :class="$style.close"
              @click="
                showMenu = !showMenu;
                unsetActiveItem();
                sendEvent('Close Small Menu');
              "
            >
              <IconClose></IconClose>
            </button>
          </li>
          <li v-if="showMenu"><div :class="$style.menu">Menu</div></li>
          <li
            v-for="(item, index) in menu.main.items"
            :key="index"
            :class="[{ [$style.active]: activeItem === `main-${index}` }]"
          >
            <button
              v-if="item.type === 'parent' && (!isMobile() || showMenu)"
              :id="`${item.link_text.toLowerCase()}-menu`"
              :aria-controls="`${item.link_text.toLowerCase()}-submenu`"
              :aria-expanded="activeItem === `main-${index}` ? 'true' : 'false'"
              :class="[{ [$style.active]: activeItem === `main-${index}` }, $style.parent]"
              @click="
                changeActiveItem(`main-${index}`);
                sendEvent(
                  `${activeItem === `main-${index}` ? 'Open' : 'Close'} ${item.link_text} Submenu`,
                );
              "
            >
              {{ item.link_text }}
            </button>
            <PreparedAnchor
              v-if="item.type === 'link' && (!isMobile() || showMenu)"
              :behavior="item.link_behavior"
              :class="$style.parent"
              :to="item.link"
              @click="
                unsetActiveItem();
                sendEvent(item.link_text, item.link);
              "
            >
              {{ item.link_text }}
            </PreparedAnchor>
            <div
              v-if="item.submenus"
              v-show="activeItem === `main-${index}`"
              :id="`${item.link_text.toLowerCase()}-submenu`"
              :aria-hidden="activeItem != `main-${index}`"
              :aria-labelledby="`${item.link_text.toLowerCase()}-menu`"
              :class="[{ [$style.wide]: item.submenus.length > 4 }, $style.submenu]"
              :inert="activeItem !== `main-${index}`"
            >
              <component
                :is="getSubmenuType(submenu.type)"
                v-for="(submenu, subIndex) in item.submenus"
                :key="subIndex"
                :class="[
                  getSubmenuClass(item),
                  'mainnav',
                  {
                    [$style.exhibitions]:
                      item.type === 'parent' &&
                      item.link_text === 'Exhibitions' &&
                      submenu.type === 'text',
                  },
                ]"
                :data="submenu.type === 'link_list' || submenu.type === 'text' ? submenu : null"
                :layout="submenu.type === 'on_view' ? 'vertical' : null"
                :limit="getLimit(submenu.type)"
                :link="submenu.more.more_link_text || undefined"
                :title="
                  (submenu.type === 'event_list' || submenu.type === 'on_view') &&
                  submenu.heading != ''
                    ? submenu.heading
                    : null
                "
                @isSamePath="handleSamePath()"
              ></component>
            </div>
          </li>
        </ul>
      </nav>
      <nav aria-label="Utility Navigation" :class="$style.util">
        <ul>
          <li v-for="(item, index) in menu.utility.items" :key="index">
            <PreparedAnchor
              v-if="item.template === 'link'"
              :to="item.link"
              :behavior="item.link_behavior"
              @click="unsetActiveItem()"
            >
              {{ item.link_text }}
            </PreparedAnchor>
            <button
              v-if="item.template === 'search'"
              id="search-menu"
              aria-controls="search-submenu"
              :aria-expanded="activeItem === 'search' ? 'true' : 'false'"
              :aria-label="activeItem != 'search' ? 'Open Search Submenu' : 'Close Search Submenu'"
              :class="$style.search"
              @click="
                unsetActiveItem();
                searchActive = !searchActive;
                sendEvent(`${activeItem != 'search' ? 'Open' : 'Close'} Search`);
              "
            >
              Search
              <IconMagnify></IconMagnify>
            </button>
          </li>
        </ul>
      </nav>
      <button
        id="burger-icon"
        aria-controls="main-menu"
        :aria-expanded="showMenu ? 'true' : 'false'"
        :aria-label="`${showMenu ? 'Close' : 'Open'} Main Menu`"
        :class="$style.burger"
        @click="
          toggleMenu();
          sendEvent(`${showMenu ? 'Open' : 'Close'} Small Menu`);
        "
      >
        <IconMenu></IconMenu>
      </button>
      <SearchModal
        v-if="searchActive"
        :active="searchActive"
        @search-state="updateSearchState"
      ></SearchModal>
    </div>
  </div>
</template>

<script setup lang="ts">
import EventList from '@/components/common/EventList.vue';
import ExhibitionsOnView from '@/components/exhibitions/ExhibitionsOnView.vue';
import IconClose from '@/assets/svgs/IconClose.svg?component';
import IconLogo from '@/assets/svgs/IconLogo.svg?component';
import IconMagnify from '@/assets/svgs/IconMagnify.svg?component';
import IconMenu from '@/assets/svgs/IconMenu.svg?component';
import { inject, onBeforeUnmount, onMounted, ref, useCssModule, watch } from 'vue';
import { Item, MainMenu } from '@/types/global.interface';
import PreparedAnchor from '@/components/common/PreparedAnchor.vue';
import SearchModal from '@/components/search/SearchModal.vue';
import { storeToRefs } from 'pinia';
import SubNav from '@/components/navigation/SubNav.vue';
import { useAnalytics, useScreenSize } from '@/composables/Common.js';
import { useMainStore } from '@/stores/mainStore';
import NavLogo from '@/components/navigation/NavLogo.vue';

const emit = defineEmits(['changeClass']);

const { ga4Event } = useAnalytics();
const { isMobile } = useScreenSize();
const store = useMainStore();
const style = useCssModule();

const { overlay, routing, showBanner } = storeToRefs(store);
const { setValue } = store;

const menu = inject<MainMenu>('mainMenu');

const activeItem = ref(null);
const frame = ref();
const lastAct = ref();
const main = ref();
const offset = ref();
const searchActive = ref(false);
const showMenu = ref(false);
const showLogoIconG = ref(false);

onBeforeUnmount(() => {
  window.removeEventListener('resize', resized);
  document.removeEventListener('keydown', escKey);
});

onMounted(() => {
  const frameClass = frame.value.classList;
  let yPos = window.scrollY;

  showLogoIconG.value = window.scrollY > 0;

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      setOffset();
      document.addEventListener('keydown', escKey);
    });
  });

  window.addEventListener('scroll', () => {
    yPos = window.scrollY;
    showLogoIconG.value = window.scrollY > offset.value;

    if (!isMobile() && yPos <= offset.value && lastAct.value !== 'unfixed') {
      lastAct.value = 'unfixed';
      emit('changeClass', 'remove', 'fixed');
      frameClass.remove(`${style.fixed}`);
    }

    if (!isMobile() && yPos > offset.value && lastAct.value !== 'fixed') {
      lastAct.value = 'fixed';
      emit('changeClass', 'add', 'fixed');
      frameClass.add(`${style.fixed}`);
    }
  });
  window.addEventListener('resize', resized);
});

watch([overlay, routing], ([overlayState, routingState]) => {
  if (!overlayState) {
    activeItem.value = null;
  }

  if (routingState) {
    activeItem.value = null;
    showMenu.value = false;
    setValue({ type: 'overlay', value: false });
  }
});

watch(searchActive, (val) => {
  setValue({ type: 'overlay', value: val });
});

watch(showBanner, () => {
  setOffset();
});

watch(showMenu, (val) => {
  setValue({ type: 'overlay', value: val });
});

function changeActiveItem(item: string): void {
  if (activeItem.value && activeItem.value === item) {
    return unsetActiveItem();
  }

  if ((activeItem.value && activeItem.value !== item) || !activeItem.value) {
    return setActiveItem(item);
  }
}

function escKey(event): void {
  if (event.key === 'Escape' && activeItem.value !== null) {
    unsetActiveItem();
  }
}

function getLimit(type: string): string | null {
  switch (type) {
    case 'event_list':
      return menu.settings.event_count;
    case 'on_view':
      return menu.settings.exhibition_count;
    default:
      return null;
  }
}

function getSubmenuClass(item: Item): string {
  switch (item.submenus.length) {
    case 1:
      return 'span-twelve';
    case 2:
      return 'span-six';
    case 3:
      return 'span-four';
    case 4:
      return 'span-three';
    case 5:
      return 'span-two';
    default:
      return '';
  }
}

function getSubmenuType(type: string): string {
  switch (type) {
    case 'event_list':
      return EventList;
    case 'link_list':
      return SubNav;
    case 'on_view':
      return ExhibitionsOnView;
    case 'text':
      return SubNav;
    default:
      return '';
  }
}

function handleSamePath(): void {
  if (isMobile()) {
    showMenu.value = !showMenu.value;
  }

  unsetActiveItem();
}

function resized(): void {
  const width = window.innerWidth;

  if (activeItem.value && width < 960) {
    showMenu.value = true;
  }

  if (activeItem.value && width >= 960) {
    showMenu.value = false;
  }

  if (!activeItem.value) {
    showMenu.value = false;
  }
}

function sendEvent(str: string, url: string | void): void {
  const event = {
    click_type: 'navigation',
    component: 'MainNav',
    gtm_tag: 'navigation',
    link_text: str,
  };

  if (url) {
    event.link_url = url;
  }

  ga4Event(event);
}

function setActiveItem(item: string): void {
  const type = menu.main.items[item.split('-')[1]].type;

  if (type === 'parent') {
    setValue({ type: 'overlay', value: true });
  }

  if (type !== 'parent') {
    setValue({ type: 'overlay', value: false });
  }

  activeItem.value = item;
}

function setOffset() {
  const banner = document.getElementById('guggen-banner');

  offset.value = banner ? banner.getBoundingClientRect().height : 0;
}

function toggleMenu(): void {
  showMenu.value = !showMenu.value;
}

function unsetActiveItem(): void {
  setValue({ type: 'overlay', value: false });
  activeItem.value = null;
}

function updateSearchState(state: boolean): void {
  setTimeout(() => {
    searchActive.value = state;
  }, 300);
}
</script>

<style lang="scss" module>
@mixin hide-icon {
  height: auto;
  overflow: auto;
  text-indent: 0;
  width: auto;

  &:focus,
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    display: none;
  }
}

:global(.child) {
  @include subtitle-2;
  @include space-0-above;
  display: inline-block;
  text-decoration: none;
}

.back,
.close {
  height: 2.125rem;
  position: absolute;
  top: 1rem;
  z-index: 2;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.back {
  @include left-arrow;
  left: 1rem;

  &:focus {
    &::before {
      color: $black;
    }
  }
}

.burger {
  height: 2.125rem;
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.close {
  right: 1rem;
}

.frame {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: $white;
  border-bottom: 1px solid $black;
  z-index: map-get($zindex, menu);
}

.icon-logo-g {
  svg {
    height: 1.5rem;
    max-width: 100%;
    width: auto;
  }
}

.logo {
  position: relative; /* Add this if not already present */
}

.logoHidden {
  opacity: 0;
}

.logoIcon {
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out; /* Adjust duration as needed */
  transition-delay: 1s;
}

.logoIconActive {
  height: px-to-rem(40);
  width: px-to-rem(165);
}

.logoIconG {
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out; /* Faster transition */
}

.logoNavActive {
  height: px-to-rem(33);
  width: px-to-rem(33);
}

.logoWrapper {
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out;
}

.main {
  align-items: center;
  display: flex;
  height: 2.125rem;
  position: relative;

  ul {
    height: 100vh;
    left: 100vw;
    padding-left: 0;
    position: fixed;
    top: 0;
    transition: transform 0.3s ease;
    width: 100vw;

    &.active {
      background: $white;
      overflow-y: scroll;
      transform: translate(-100vw);
      z-index: 1;
    }

    li {
      width: inherit;

      &.active {
        background: $white;
        position: absolute;
        text-align: center;
        top: 0;
      }
    }

    ul {
      left: 0;
      position: inherit;
    }
  }

  .logo {
    display: inline-block;
    height: px-to-rem(40);
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    width: px-to-rem(165);
    z-index: 0;

    &:focus {
      @include focus-outline;
      background-color: transparent;
    }

    .logoWrapper {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      .logoIcon {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.4s ease-in-out;
        width: 100%;
      }

      .logoIconG {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.4s ease-in-out;
        width: 100%;
      }

      .logoVisible {
        opacity: 1;
        transition-delay: 0.25s;
      }
    }
  }
}

.menu {
  @include subtitle-1;
  @include space-0-above;
  border-bottom: 1px solid $light-gray;
  padding: px-to-rem(21.7) 0;
  text-align: center;
}

.nav {
  padding: 1rem;
  position: relative;
  z-index: map-get($zindex, menu);

  ul {
    margin: 0;

    li {
      list-style: none;

      &.active {
        margin-left: 0;
      }
    }
  }
}

.parent {
  @include subtitle-1;
  @include space-0-above;
  display: inline-block;
  padding: 1rem 0 1rem 1rem;
  text-decoration: none;
  white-space: nowrap;

  &.active {
    padding: px-to-rem(21.7) 0;
  }

  &:hover {
    background: transparent;
    outline: none;
    text-decoration: underline;
  }
}

.submenu {
  background: $white;
  border-top: 1px solid $light-gray;
  min-height: 100vh;
  padding: 0 1rem 4rem 1rem;
  text-align: initial;

  > div {
    @include space-24-32-above;
  }

  h2 {
    @include space-0-above;
  }

  ul {
    height: auto;
    padding-left: 0;
    width: auto;

    li {
      padding: 0;
    }
  }

  :global(.mainnav) {
    margin-top: 2rem;

    + :global(.mainnav) {
      margin-top: 3rem;
    }

    > div {
      p {
        @include body-2;
        @include space-22-27-above;
      }
    }

    h2,
    h5 {
      @include space-0-above;
    }
    li {
      a:not(:global(.more-link)) {
        color: $gray-3;
        text-decoration: none;

        &:hover {
          color: $gray-3-hover;
          text-decoration: underline;
        }
        &:focus {
          color: $white;
        }
        &:active {
          color: $gray-3-active;

          &:focus {
            color: $white;
          }
        }
      }
    }
  }
}

.util {
  align-items: center;
  display: flex;
  height: 2.125rem;
  position: absolute;
  right: 3.25rem;
  top: 1rem;

  a {
    color: $black;
    display: inline-block;

    &:focus {
      @include focus-outline;
      background-color: transparent;
    }
  }

  button,
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  ul {
    display: flex;
    gap: 12px;
    line-height: 24px;
    justify-content: flex-end;
    height: 1.5rem;
  }

  li {
    a,
    button {
      font-size: 0;
      position: relative;
      text-decoration: none;
      text-transform: none;
    }

    &:first-of-type {
      a {
        @include body-2;
        margin-top: 1px;
        vertical-align: text-top;

        &:focus {
          color: $black;
        }
      }
    }

    &:nth-of-type(2) {
      display: none;
    }
  }
}

.main {
  .logo {
    svg {
      height: 2.4375rem;
    }
  }
}

@media (min-width: $break-point) {
  .back,
  .burger {
    display: none;
  }

  .icon {
    display: none;
  }

  .frame {
    position: relative;

    &.fixed {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: map-get($zindex, menu);

      .util {
        li {
          a,
          button {
            @include hide-icon;
          }
        }
      }
    }

    .util {
      right: 2rem;
      top: 1.5rem;
      transition: top 0.25s ease;

      ul {
        gap: 2rem;
      }

      li {
        &:nth-of-type(2) {
          display: initial;
        }

        a,
        button {
          @include hide-icon;
          @include body-2;
          vertical-align: top;

          &:focus,
          &:hover {
            background: transparent;
            color: initial;
            outline: none;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        > a,
        button {
          margin-top: 0;
        }
      }
    }
  }

  .nav {
    max-width: 80em;
    margin: 0 auto;
    padding: 2rem 2rem px-to-rem(7) 2rem;
  }

  .main {
    display: block;
    height: initial;

    .logo {
      line-height: 0;

      &:focus {
        @include focus-outline;
        background-color: transparent;
        outline-offset: 0.5rem;
      }
    }

    ul {
      align-items: baseline;
      display: grid;
      grid-auto-columns: min-content;
      grid-auto-flow: column;
      height: auto;
      left: 0;
      list-style: none;
      padding: 0;
      position: relative;
      top: px-to-rem(7);
      width: auto;

      li {
        margin-right: 36px;
        padding: 0;
        text-align: initial;
        width: auto;

        &.active {
          background: transparent;
          position: initial;
          top: auto;
        }
      }

      ul {
        display: block;
        padding: 0;

        li {
          margin-right: 0;
        }
      }
    }
  }

  .logoNavActive {
    height: px-to-rem(50);
    width: px-to-rem(50);
  }

  .parent {
    cursor: pointer;
    margin: 0;
    padding: px-to-rem(15) 0 px-to-rem(28);

    &.active {
      background: transparent;
      border-bottom: 2px solid $black;
      outline: none;
      padding: px-to-rem(15) 0 px-to-rem(28);
      text-decoration: none;
    }

    &:focus {
      @include focus;
    }
  }

  .submenu {
    border: 1px solid $black;
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: repeat(12, [col-start] 1fr);
    left: -2rem;
    max-width: $desktop-width;
    min-height: auto;
    padding: 2rem;
    position: absolute;
    top: 100%;
    width: 100%;

    :global(.mainnav) {
      @include space-0-above;

      + :global(.mainnav) {
        @include space-0-above;
      }
    }

    :global(.no-heading) {
      margin-top: 2rem;

      :first-child {
        margin-top: 0;
      }
    }

    &.wide {
      padding-right: 2rem;
      width: 100%;
    }

    > div {
      margin-top: 0;

      &.exhibitions:first-of-type {
        margin-top: 0;
      }
    }

    h5 {
      margin-top: 0;
    }

    ul {
      top: 0;

      li {
        padding: 0;
      }
    }
  }
}
</style>
