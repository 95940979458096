export function changeCase(slug) {
  const s = slug[0].toUpperCase() + slug.slice(1);

  return s.replace(/(-.)/g, x => x[1].toUpperCase());
}

/**
 * Converts datetime duration into accessible human readable time
 * @param {*} duration
 */
export function convertDurationToHumanReadableTime(duration) {
  const pattern = /H*(\d*)M*(\d*)S*(\d*)/;
  const time = duration.match(pattern);
  let timeDuration = [];

  if (time[1].length) {
    timeDuration.push(`${time[1]} hour${time[1] > 1 ? 's' : ''}`);
  }

  if (time[2].length) {
    timeDuration.push(`${time[2]} minute${time[2] > 1 ? 's' : ''}`);
  }

  if (time[3].length) {
    timeDuration.push(`${time[3]} second${time[3] > 1 ? 's' : ''}`);
  }

  return timeDuration.join(' ');
}

export function decodeContent(content) {
  const txt = document.createElement('textarea');

  txt.innerHTML = content;
  const result = txt.value;

  txt.remove();

  return result;
}

/**
 * Format duration in milliseconds to hh:mm:ss
 */
export function formatDuration(d) {
  const hours = Math.floor(d / (1000 * 60 * 60));
  let remainder = d % (1000 * 60 * 60);
  let result = '';

  const minutes = Math.floor(remainder / (1000 * 60));
  remainder = d % (1000 * 60);

  const seconds = Math.floor(remainder / 1000);

  if (hours > 0) {
    result = `${hours}:`;
  }

  if (minutes > 0) {
    if (minutes <= 9) {
      result = `${result}0${minutes}:`;
    } else {
      result = `${result}${minutes}:`;
    }
  } else {
    result = `${result}00:`;
  }

  if (seconds > 0) {
    if (seconds <= 9) {
      result = `${result}0${seconds}`;
    } else {
      result = `${result}${seconds}`;
    }
  } else {
    result = `${result}00`;
  }

  return result;
}

/**
 * Parses an HH:MM:SS string and returns the datetime duration in machine-readable duration time component scale format.
 * https://www.w3.org/TR/2014/REC-html5-20141028/infrastructure.html#duration-time-component
 *
 * @param {*} str
 */
export function formatTimeDuration(str) {
  let result = '';

  if (str) {
    const parts = str.split(':');
    const seconds = parts.pop();
    const minutes = parts.pop();
    const hours = parts.pop();

    result += parseInt(hours) ? `H${hours}` : '';
    result += parseInt(minutes) ? `M${minutes}` : '';
    result += parseInt(seconds) ? `S${seconds}` : '';
  }

  return result;
}

/**
 * Returns a cookie by name
 * @param {*} value
 */
export function getCookie(value) {
  return document.cookie.split(';').filter(item => item.includes(value));
}

/**
 *
 * @param {*} string
 */
export function intToWord(int) {
  const words = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ];

  return words[int];
}

/**
 * Tests wether or not a URL is absolute:
 *
 * 'http://example.com' // true - regular http absolute URL
 * 'HTTP://EXAMPLE.COM' // true - HTTP upper-case absolute URL
 * 'https://www.exmaple.com' // true - secure http absolute URL
 * 'ftp://example.com/file.txt' // true - file transfer absolute URL
 * '//cdn.example.com/lib.js' // true - protocol-relative absolute URL
 * '/myfolder/test.txt' // false - relative URL
 * 'test' // false - also relative URL
 * @param {*} dt
 */
export function isAbsoluteUrl(url) {
  const pattern = new RegExp('^(?:[a-z]+:)?//', 'i');

  return pattern.test(url);
}

export function redirectUnsupportedBrowser(router, title) {
  const browser = navigator.userAgent;
  const browsers = {
    1: { edge: /Edge\/([\d\.]*)/ },
    2: { chrome: /Chrome\/([\d\.]*)/ },
    3: { firefox: /Firefox\/([\d\.]*)/ },
    4: { ie: /MSIE ([\d\.]*)/ },
    5: { safari: /Version\/([\d\.]*)/ }
  };
  const support = appConfig.browserSupport;

  // use methods that are available to older browsers
  // order is important
  for (let i = 1; i < 6; i++) {
    const agent = Object.keys(browsers[i])[0];
    const pattern = browsers[i][agent];
    const version = browser.match(pattern);

    browsers[i][agent] = version ? Number(version[1].split('.')[0]) : version;
  }

  if (!browser.match(/Mobile/)) {
    for (let i = 1; i < 6; i++) {
      const agent = Object.keys(browsers[i])[0];

      if (browsers[i][agent]) {
        // check against versions saved in wp options
        if (Number(support[agent]) > browsers[i][agent]) {
          router.push({ path: '/browser-not-supported' });

          const params = {
            click_type: 'unsupported_browser',
            component: 'router.js',
            content_group: 'error',
            link_text: `${agent}_${browsers[i][agent]}`,
            gtm_tag: 'interaction'
          };

          window.dataLayer.push({
            event: 'ga4-event',
            name: 'error',
            params
          });
        }
        break;
      }
    }
  }
}

// Convert slugs to readable titles
export function slugToTitle(str) {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// Remove HTML tags from a string, cleaning up any leftover whitespace.
export function stripTags(str) {
  return str.replace(/(<([^>]+)>)/gi, '').trim();
}

// https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
export function slugify(string) {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

/**
 * Uppercase the first letter of each word.
 */
export function upperCaseWords(str) {
  return str.replace(/[^\s]+/g, function(word) {
    return word.replace(/^./, function(first) {
      return first.toUpperCase();
    });
  });
}
